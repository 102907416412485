import React, { ComponentProps, useEffect, useRef, useState, useMemo, memo, CSSProperties, ReactElement, UIEvent, useCallback } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { GridOnItemsRenderedProps, VariableSizeList as List } from 'react-window'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import {
  ActivitiesLayout,
  ActivityDetails,
  PickFirstContainer,
  MoveIconHint,
  ColumnHeader,
  ActivitiesTimeline,
  ActivityRow
} from '_core/components/ActivitiesList'
import ActivitiesPickerDialog from '_core/components/dialogs/ActivitiesPicker'
import Empty from '_core/components/Empty'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { useWide } from '_core/components/layout'
import { VirtalizedListCarousel, VirtalizedGridCarousel, PrevButton, NextButton, easeInOutCubic } from '_core/components/VirtalizedCarousel'

import useActivitiesCompanies from '_core/hooks/useActivitiesCompanies'
import useActivitiesGroups, { LocalActivityItem } from '_core/hooks/useActivitiesGroups'
import useActivitiesSlidesToShow, { maxSlidesLength, timeColumnWidth } from '_core/hooks/useActivitiesSlidesToShow'
import useActivitiesSlots, { ActivityItem } from '_core/hooks/useActivitiesSlots'
import useCurrentSlideIndex from '_core/hooks/useCurrentSlideIndex'
import useDialog from '_core/hooks/useDialog'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import { useLookUpCompanies } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useVirtualized from '_core/hooks/useVirtualized'

import { getSkeletonSize } from '_core/helpers/skeleton'

import { formatTime, getLocal } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles<void | Partial<{ cellWidth: number }>>()((theme, props) => ({
  cell: {
    maxWidth: props?.cellWidth
  }
}))

const PickCompanies = ({
  addCompany,
  disabled,
  isIcon
}: {
  addCompany: ComponentProps<typeof ActivitiesPickerDialog>['add']
  disabled: boolean
  isIcon: boolean
}) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog(false)
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const memoCustomList = useMemo(() => queryParams.customList || [], [queryParams.customList])

  const { lookupCompanies, forceAbort } = useLookUpCompanies()
  const [tags, setTags] = useState<{ md5: string; name: string; urlText: string }[] | null>(null)

  const loadOptions = useCallback(
    async (searchTerm?: string, skip?: string[], take?: number) => {
      const resData = await lookupCompanies(searchTerm, [...memoCustomList, ...(skip || [])], take)
      return resData?.map((item) => ({ name: item.CompanyNameText, urlText: item.BestUrlText, md5: item.CompanyMd5 }))
    },
    [memoCustomList, lookupCompanies]
  )

  useEffect(() => {
    ;(async () => {
      if (isDialogOpened) {
        setTags(null)
        const tags = await loadOptions('', memoCustomList, memoCustomList.length + 3)
        if (tags) {
          setTags(tags)
        }
      }
    })()
  }, [memoCustomList, isDialogOpened, loadOptions])

  return (
    <>
      <ActivitiesPickerDialog.TriggerEl
        open={openDialog}
        disabled={disabled}
        icon={['far', 'user-plus'] as IconProp}
        text="Add companies"
        isIcon={isIcon}
      />
      <ActivitiesPickerDialog
        title="Add companies"
        loadOptions={loadOptions}
        forceAbort={forceAbort}
        isOpened={isDialogOpened}
        close={closeDialog}
        add={addCompany}
        tags={tags?.slice(0, 3)}
      />
    </>
  )
}

const TSlot = memo(
  ({
    md5,
    loading,
    from = [],
    to = [],
    action,
    companies = [],
    detailsLink,
    dateTime = ''
  }: { md5: string; loading: boolean; dateTime: string } & LocalActivityItem) => {
    const isFutureEvent = getLocal(dateTime).isAfter(getLocal())

    const hasCompanyColleages = from.find(({ companyMd5 }) => companyMd5 === md5)

    const [f = [], t = []] = hasCompanyColleages ? [from, to] : [to, from]

    const iconsMap = {
      inbound: ['fas', 'message-arrow-down'],
      outbound: ['fas', 'message-arrow-up'],
      meeting: ['far', 'calendar-alt']
    }

    const { text = '' } =
      [
        {
          text: 'received message from',
          condition: f.length && !hasCompanyColleages && action !== 'meeting'
        },
        {
          text: 'sent message to',
          condition: f.length && hasCompanyColleages && action !== 'meeting'
        },
        {
          text: isFutureEvent ? 'will meet' : 'met',
          condition: f.length && action === 'meeting'
        }
      ].find(({ condition }) => condition) || {}

    return (
      <ActivityDetails
        time={formatTime(dateTime)}
        isInternal={companies.length === 1}
        companies={companies.filter((domain) => !!domain) as string[]}
        from={f}
        to={t}
        detailsLink={detailsLink}
        actionText={text}
        icon={action ? (iconsMap[action] as IconProp) : null}
        loading={loading}
      />
    )
  }
)

const Cell = memo(
  ({
    style,
    columnIndex,
    rowIndex,
    data: { rows, columns, loading, setSize, width, resetSize }
  }: {
    style: CSSProperties
    columnIndex: number
    rowIndex: number
    data: {
      rows: ActivityRow[]
      columns: string[]
      loading: boolean
      setSize: (rowIndex: number, height: number) => void
      resetSize: (rowIndex: number, columnIndex: number) => void
      width: number
    }
  }) => {
    const { classes } = useStyles({ cellWidth: width })
    const cMd5 = columns[columnIndex]
    const timeSlot = rows[rowIndex] || {}
    const [dateTime] = Object.keys(timeSlot)
    const [entityMap = {}] = Object.values(timeSlot) as [{ [x: string]: LocalActivityItem }]

    const cellRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      resetSize(rowIndex, columnIndex)
    }, [width, rowIndex, columnIndex])

    useEffect(() => {
      if (cellRef.current) {
        setSize(rowIndex, cellRef.current.getBoundingClientRect().height + 16)
      }
    }, [rowIndex, loading, width])

    return (
      <div style={style}>
        <div ref={cellRef} className={classes.cell}>
          <TSlot loading={loading} md5={cMd5} dateTime={dateTime} {...(entityMap[cMd5 as keyof typeof entityMap] || {})} />
        </div>
      </div>
    )
  }
)

const HeaderRow = ({ style, index, data: { items } }: { style: CSSProperties; index: number; data: { items: ReactElement[] } }) => {
  return <div style={style}>{items[index]}</div>
}

const ActivitiesByCompany = ({
  width,
  onCustomSelect,
  reset,
  onLoading
}: {
  width: number
  onCustomSelect: ComponentProps<typeof ActivitiesPickerDialog>['add']
  reset: () => void
  onLoading: (loading: boolean) => void
}) => {
  const wide = useWide()
  const { search } = useLocation()
  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const myUserKey = userKeyResult?.results.UserKeyMd5

  const headerRef = useRef<List>(null)
  const timelineRef = useRef<List>(null)
  const sizeMap = useRef<{ [key: number]: number }>({})
  const [[firstOverscanVisibleRow, firstOverscanVisibleColumn], setFirstOverscanVisibleValues] = useState<
    [GridOnItemsRenderedProps['overscanRowStartIndex'], GridOnItemsRenderedProps['overscanColumnStartIndex']]
  >([0, 0])

  const { slideIndex, updateSlideIndex } = useCurrentSlideIndex()

  const { list: companiesList, listMd5s: companiesListMd5s, loading: listLoading } = useActivitiesCompanies()

  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { groupBy, view, customList = [] } = queryParams

  const containerWidth = width - timeColumnWidth - 12

  const customView = groupBy === 'Contributor' || view === 'Custom'
  const skeletonSize = companiesListMd5s?.length || customList.length || maxSlidesLength

  const { slidesToShow, columnWidth, isAddButtonIcon, getColumnSize } = useActivitiesSlidesToShow(skeletonSize, containerWidth)

  const shouldSelectCompany = groupBy === 'Company' && view === 'Custom' && companiesListMd5s ? !companiesListMd5s.length : !customList?.length

  const { slots, hasMore, loading: slotsLoading, more, reload } = useActivitiesSlots({ key: 'JustTheseEmployerAnchorMd5s', list: companiesListMd5s })

  const groupLists = useActivitiesGroups()

  const loading = listLoading || slotsLoading || !myUserKey

  useEffect(() => {
    onLoading(loading)
  }, [loading])

  const onContainerScroll = (e: UIEvent<HTMLDivElement>) => {
    if (headerRef.current) {
      headerRef.current.scrollTo(e.currentTarget.scrollLeft)
    }
  }

  const outerElementStyle = useMemo(
    () => ({ width: containerWidth, overflow: 'hidden', minHeight: `calc(100vh - ${wide ? 262 : 242})px` }) as CSSProperties,
    [containerWidth, wide]
  )
  const { ref: gridRef, outerElementType } = useVirtualized(true, {}, { containerStyle: outerElementStyle, onContainerScroll })

  useEffect(() => {
    if (gridRef.current && headerRef.current && timelineRef.current) {
      gridRef.current.resetAfterRowIndex(0)
      gridRef.current.resetAfterColumnIndex(0)
      headerRef.current.resetAfterIndex(0)
      headerRef.current.scrollTo(0)
      timelineRef.current.resetAfterIndex(0)
      updateSlideIndex(0)
    }
  }, [containerWidth, gridRef, search, updateSlideIndex])

  const list = useMemo(
    () =>
      !loading && companiesListMd5s
        ? slots.map((slot) => {
            const [date] = Object.keys(slot)
            const [timeSlots] = Object.values(slot)

            return {
              [date]: timeSlots.map((timeSlot) => {
                const [time] = Object.keys(timeSlot)
                const [timeItems] = Object.values(timeSlot) as ActivityItem[][]

                const lists = myUserKey ? groupLists(timeItems, time, myUserKey) : []

                return {
                  [time]: companiesListMd5s.reduce(
                    (acc, cMd5) => ({
                      ...acc,
                      ...lists.reduce(
                        (acc, { from, to, action, companies, detailsLink }) =>
                          [...from, ...to].find(({ companyMd5 }) => cMd5 === companyMd5)
                            ? { ...acc, [cMd5]: { from, to, action, companies, detailsLink } }
                            : acc,
                        {}
                      )
                    }),
                    {} as {
                      [id: string]: LocalActivityItem
                    }
                  )
                }
              })
            }
          })
        : [],
    [loading, myUserKey, slots]
  )

  const flatRows = useMemo(
    () =>
      loading
        ? getSkeletonSize(7)
        : list
            .map((slot) => {
              const [timeSlots = []] = Object.values(slot)
              return timeSlots
            })
            .flat(),
    [loading, list]
  ) as ActivityRow[]

  const setSize = (index: number, size: number) => {
    if (gridRef.current && timelineRef.current && headerRef.current) {
      const condition = loading ? true : (sizeMap.current[index] || 0) < size

      sizeMap.current = { ...sizeMap.current, [index]: condition ? size : sizeMap.current[index] }
      gridRef.current.resetAfterRowIndex(index)
      headerRef.current.resetAfterIndex(index)
      timelineRef.current.resetAfterIndex(index)
    }
  }

  const getSize = (index: number) => sizeMap.current[index] || 50

  const handleRemoveCompany = (md5: string) => {
    onCustomSelect(customList?.filter((customItemMd5) => customItemMd5 !== md5))
  }

  const handleAddCompany = (md5s: string[]) => {
    onCustomSelect([...customList, ...md5s])
  }

  const goBack = () => {
    updateSlideIndex((prevState = 0) => prevState - 1)
  }

  const goNext = () => {
    updateSlideIndex((prevState = 0) => prevState + 1)
  }

  const onGridItemsRendered = (props: GridOnItemsRenderedProps) => {
    setFirstOverscanVisibleValues([props.overscanRowStartIndex, props.overscanColumnStartIndex])
  }

  const resetSize = (rowIndex: number, columnIndex: number) => {
    if (rowIndex === firstOverscanVisibleRow && firstOverscanVisibleColumn === columnIndex) {
      sizeMap.current = {}
    }
  }

  const actionButton = customView ? (
    <PickCompanies disabled={!shouldSelectCompany && !companiesList} addCompany={handleAddCompany} isIcon={isAddButtonIcon} />
  ) : null

  return (
    <>
      {shouldSelectCompany && <PickFirstContainer width={width}>{actionButton}</PickFirstContainer>}
      {!shouldSelectCompany && (
        <Box display="grid" gridTemplateColumns={`${timeColumnWidth}px auto 50px`}>
          <ActivitiesTimeline ref={timelineRef} rowHeight={getSize} width={timeColumnWidth} rows={flatRows} loading={loading} />
          <ActivitiesLayout
            header={
              <VirtalizedListCarousel
                slideIndex={slideIndex}
                style={{ overflow: 'hidden' }}
                ref={headerRef}
                height={98}
                slidesToShow={slidesToShow}
                getColumnSize={getColumnSize}
                prevArrow={<PrevButton onClick={goBack} />}
                nextArrow={<NextButton onClick={goNext} />}
                itemCount={skeletonSize}
                width={containerWidth}
                duration={700}
                easing={easeInOutCubic}
                layout="horizontal"
                itemData={{
                  items: (companiesList || getSkeletonSize(skeletonSize, {})).map(
                    ({ name = '', md5 = '', urlText = '' }, index: number, arr: object[]) => (
                      <ColumnHeader
                        key={md5}
                        name={name}
                        md5={md5}
                        logoUrl={urlText}
                        link={`${Paths._companies}/${md5}`}
                        onDelete={handleRemoveCompany}
                        actionButton={customView ? actionButton : null}
                        isLastChild={index === arr?.length - 1}
                      />
                    )
                  )
                }}
              >
                {HeaderRow}
              </VirtalizedListCarousel>
            }
          >
            {!loading && list.length === 0 && (
              <div>
                <Empty
                  title="No activities were found"
                  subTitle="Nothing was found for the given date and filter parameters. Try change your input data."
                  icon={<FontAwesomeIcon size="5x" icon={['fat', 'calendar-clock']} style={{ color: '#A7A7A7' }} />}
                  action={
                    <Button variant="link" onClick={reset} color="primary" disablePT>
                      clear all filters
                    </Button>
                  }
                  close={false}
                />
              </div>
            )}
            {(loading || list.length > 0) && reload && (
              <InfiniteScroll
                loading={loading}
                dataLength={flatRows.length}
                next={more}
                refreshFunction={reload}
                hasMore={hasMore}
                scrollableTarget="activities_list"
              >
                <VirtalizedGridCarousel
                  slideIndex={slideIndex}
                  columnCount={skeletonSize}
                  overscanRowCount={2}
                  getColumnSize={getColumnSize}
                  height={window.innerHeight}
                  width={containerWidth}
                  slidesToShow={slidesToShow}
                  rowCount={flatRows.length}
                  rowHeight={getSize}
                  duration={700}
                  easing={easeInOutCubic}
                  outerElementType={outerElementType}
                  ref={gridRef}
                  onItemsRendered={onGridItemsRendered}
                  itemData={{
                    rows: flatRows,
                    columns: companiesListMd5s || getSkeletonSize(skeletonSize, ''),
                    loading,
                    setSize,
                    resetSize,
                    width: columnWidth
                  }}
                >
                  {Cell}
                </VirtalizedGridCarousel>
              </InfiniteScroll>
            )}
          </ActivitiesLayout>
          {!loading && <MoveIconHint />}
        </Box>
      )}
    </>
  )
}

export default ActivitiesByCompany
